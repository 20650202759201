<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :loading="loading"
          loading-text="Loading... Please wait"
          :headers="headers"
          :items="targetData"
          :search="search"
          :footer-props="{
            itemsPerPageOptions: [5, 100, 150, { text: 'All', value: -1 }],
            showCurrentPage: true,
          }"
        >
          <!-- outlet template -->
          <template v-slot:item.id="props">
            <v-btn
              color="flatButton"
              text
              :to="`/targets/slp_targets/${props.item.id}`"
            >
              <v-icon color="green">mdi-eye</v-icon></v-btn
            >
          </template>
          <template v-slot:item.Achievement>0</template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
  
  <script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["user"]),
  },

  data: () => ({
    dataItem: {},
    Items: [],
    search: null,
    dialog: false,
    showItems: [],
    targetData: [],
    SlpName: null,
    TargetValue: null,
    skus: [],
    editMode: false,
    TotalDocTotal: 0,
    progress: 0,
    total: 0,
    completed: 0,
    headers: [
      { text: "Target Code", value: "TCode" },
      { text: "Target Name", value: "TName" },
      { text: "Target Value", value: "Tvalue" },
      // { text: "Achievement", value: "Achievement" },
      { text: "Period Start", value: "PeriodStart" },
      { text: "Period End", value: "PeriodEnd" },
      { text: "Comment", value: "setup.Comment" },
      // { text: "RecurPat", value: "setup.RecurPat" },
      { text: "Action", value: "id" },
    ],
  }),

  watch: {
    "$route.params.id": {
      handler: "getTarget",
      immediate: true,
    },
  },
  methods: {
    getTarget(val) {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/targets/${val}`)
        .then((res) => {
          console.log(res.ResponseData, "single target");
          self.targetData = res.ResponseData;
          this.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
          this.loading = false;
        });
    },

    editItem(item) {
      console.log(item);
      this.showItems = Object.assign([], item);
      this.dialog = true;
    },
  },
  created() {},
};
</script>
  <style>
.progress-bar {
  width: 100%;
  height: 20px;
  background-color: #ddd;
  border-radius: 5px;
}
.progress {
  height: 100%;
  background-color: #0051ff;
  border-radius: 5px;
}
</style>